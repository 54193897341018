import { Route } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorComponent/ErrorBoundary';
import { SendIncidencesServiceManagerProvider } from '../Services/Incidences/SendIncidencesServices';
import { OrganizationChartServiceManagerProvider } from '../Services/OrganizationChart/OrganizationCharService';

import { InvitationsServiceManagerProvider } from '../Services/Invitations/InvitationsService';
import { EmployeeMaintenanceServiceManagerProvider } from '../Services/Operations/employeeMaintenance/EmployeeMaintenanceServiceManager';

// Carga diferida de los componentes de las rutas

import { AssistScreen } from '../screens/Assist';
import { CollaboratorScreen } from '../screens/Collaborators';
import { HomeScreen } from '../screens/Home';
import { VacationScreen } from '../screens/Vacations';
import { InvitationsScreen } from './../screens/Invitations/index';
import { Logout } from './../screens/Logout/index';
import { NotFound } from './../components/NotFound/index';
import { ConfigurationScreen } from '../screens/Configuration';
import { Catalogs } from '../screens/Catalogs';
import { Operations } from '../screens/Operation/Index';
import { IncidencesScreen } from '../screens/Incidenses';
import { QueriesComponent } from '../screens/Queries';
import { OrganizationChart } from '../screens/OrganizationChart';

// Función para memorizar el componente cargado

const routerBrowser = [
	{
		path: '/',
		name: 'Home',
		element: <HomeScreen />,
	},
	{
		path: '/applications',
		name: 'Applications',
		element: (
			<ErrorBoundary>
				<CollaboratorScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/vacations',
		name: 'Vacations',
		element: (
			<ErrorBoundary>
				<VacationScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/assist',
		name: 'Assist',
		element: (
			<ErrorBoundary>
				<AssistScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/invitations',
		name: 'Invitations',
		element: (
			<ErrorBoundary>
				<InvitationsServiceManagerProvider>
					<InvitationsScreen />
				</InvitationsServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '/centralModuleLogout',
		name: 'logout',
		element: <Logout />,
	},
	{
		path: '/catalogs',
		name: 'catalogs',
		element: (
			<ErrorBoundary>
				<Catalogs />
			</ErrorBoundary>
		),
	},
	{
		path: '/configuration',
		name: 'configuration',
		element: (
			<ErrorBoundary>
				<ConfigurationScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/operation',
		name: 'operation',
		element: (
			<ErrorBoundary>
				<EmployeeMaintenanceServiceManagerProvider>
					<Operations />
				</EmployeeMaintenanceServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '/incidences',
		name: 'incidences',
		element: (
			<ErrorBoundary>
				<SendIncidencesServiceManagerProvider>
					<IncidencesScreen />
				</SendIncidencesServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '/queries',
		name: 'queries',
		element: (
			<ErrorBoundary>
				<QueriesComponent />
			</ErrorBoundary>
		),
	},
	{
		path: '/organizationChart',
		name: 'organizationChart',
		element: (
			<ErrorBoundary>
				<OrganizationChartServiceManagerProvider>
					<OrganizationChart />
				</OrganizationChartServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '*',
		name: 'NotFound',
		element: <NotFound />,
	},
];

export default routerBrowser.map((route) => {
	return <Route key={route.name} path={route.path} element={route.element} />;
});
