import { TableNames } from '../../../../namesConstants/names';

const normalBodyTemplate = (row, col) => {
	return row[col];
};

export const dataTableOrgChart = [
	{
		field: 'code',
		headerName: TableNames.TableCode,
		sorteable: true,
		body: normalBodyTemplate,
		style: { flexGrow: 1, flexBasis: '30px' },
	},
	{
		field: 'fullName',
		headerName: TableNames.TableName,
		sorteable: true,
		body: normalBodyTemplate,
		style: { flexGrow: 1, flexBasis: '100px' },
	},
	{
		field: 'position',
		headerName: TableNames.TablePosition,
		sorteable: true,
		body: normalBodyTemplate,
		style: { flexGrow: 1, flexBasis: '100px' },
	},
	{
		field: 'department',
		headerName: TableNames.TableDepartment,
		sorteable: true,
		body: normalBodyTemplate,
		style: { flexGrow: 1, flexBasis: '100px' },
	},
	{
		field: 'actualLeaderFullName',
		headerName: TableNames.TableActualLeader,
		sorteable: true,
		body: normalBodyTemplate,
		style: { flexGrow: 1, flexBasis: '100px' },
	},
	{
		field: 'actualLeaderCompanyName',
		headerName: TableNames.TableCompanyName,
		sorteable: true,
		body: normalBodyTemplate,
		style: { flexGrow: 1, flexBasis: '100px' },
	},
];
