/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { StoreContext } from '../../../business/Provider';
import {
	LOADING_ON,
	LOADING_OFF,
	GET_OPERATION_COLABORATORS_SUCCESS,
	POST_WORKCENTER_LOCATION_COLABORATORS,
	GET_OPERATION_SCHEDULE_COLABORATORS_SUCCESS,
	DELETE_OPERATION_SCHEDULE_COLABORATOR_SUCCESS,
	DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR,
	GET_WORKCENTER_LOCATION_COLABORATORS_SUCCESS,
	GET_OPERATION_COLABORATORS,
	GET_OPERATION_COLABORATORS_ERROR,
	GET_WORKCENTER_LOCATION_COLABORATORS,
} from '../../../business/constants';
import { generateUrlBase } from '../../../utils/utils';
import useApi from '../../api';
import { Toast } from '../../../components/toast';

const useOperation = () => {
	const context = useContext(StoreContext);
	const { genericApiCall } = useApi();
	const {
		dispatchLoading,
		dispatchGetOperationCollaborators,
		dispatchGetOperationWorkCenterLocationCollaborators,
		dispatchPostOperationWorkCenterLocationCollaborators,
		dispatchGetSchenduleCollaboratorOperation,
		dispatchDeleteScheduleCollaborator,
		postWorkShiftScheduleContextState,
	} = context;
	const {
		postWorkShiftSchedule,
		postWorkShiftScheduleSuccess,
		postWorkShiftScheduleError,
	} = postWorkShiftScheduleContextState;

	const getCollaboratorsOnOperation = async () => {
		const baseUrl = generateUrlBase('operations/collaborators');
		dispatchLoading({ type: LOADING_ON });
		dispatchGetOperationCollaborators({
			type: GET_OPERATION_COLABORATORS,
		});
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetOperationCollaborators({
					type: GET_OPERATION_COLABORATORS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetOperationCollaborators({
					type: GET_OPERATION_COLABORATORS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
			dispatchGetOperationCollaborators({
				type: GET_OPERATION_COLABORATORS_ERROR,
				payload: error.message,
			});
		}
	};

	const getWorkCenterLocationCollaboratorsOnOperation = async (
		personiacollaboratorid,
		type
	) => {
		const baseUrl = generateUrlBase(
			`collaborators/${personiacollaboratorid}/work-center?type=${type}`
		);
		dispatchGetOperationWorkCenterLocationCollaborators({
			type: GET_WORKCENTER_LOCATION_COLABORATORS,
		});
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});

			const payload = result.data;
			if (result.status === 200) {
				dispatchGetOperationWorkCenterLocationCollaborators({
					type: GET_WORKCENTER_LOCATION_COLABORATORS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				return payload;
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postWorkCenterLocationCollaboratorsOnOperation = async (
		personiacollaboratorid,
		Data
	) => {
		const baseUrl = generateUrlBase(
			`collaborators/${personiacollaboratorid}/work-center`
		);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'POST', Data, {}, {});
			if (result.status === 200 || result.status === 201) {
				dispatchPostOperationWorkCenterLocationCollaborators({
					type: POST_WORKCENTER_LOCATION_COLABORATORS,
					payload: result.data,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast(
					'success',
					'Se han asignado los centros de trabajo al colaborador de forma exitosa'
				);
				return getCollaboratorsOnOperation();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postWorkShiftMassiveCollaboratorsOnOperation = async (Data) => {
		const baseUrl = generateUrlBase(`collaborators/work-shift/schedule`);
		dispatchLoading({ type: LOADING_ON });
		postWorkShiftSchedule();
		try {
			const result = await genericApiCall(baseUrl, 'POST', Data, {}, {});
			if (result.status === 200 || result.status === 201) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Se han asignado nuevos turnos a los colaboradores');
				return getCollaboratorsOnOperation();
			} else if (result.status === 207) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('warning', result.data.detail);
				getCollaboratorsOnOperation();
				return {
					status: 207,
					data: result.data,
				};
			} else if (result.status === 404) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('warning', result.data.detail);
			} else if (result.status === 409) {
				dispatchLoading({ type: LOADING_OFF });
				postWorkShiftScheduleSuccess(result.data.errorData);
			} else if (result.status === 500) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('error', 'Se encontró un error, notifique a su administrador');
			}
			return result;
		} catch (error) {
			postWorkShiftScheduleError(error.message);
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const postWorkShiftSingleCollaboratorsOnOperation = async (id, Data) => {
		const baseUrl = generateUrlBase(`collaborators/${id}/work-shift/schedule`);
		dispatchLoading({ type: LOADING_ON });
		postWorkShiftSchedule();
		try {
			const result = await genericApiCall(baseUrl, 'POST', Data, {}, {});

			if (result.status === 200 || result.status === 201) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('success', 'Se ha asignado un nuevo turno');
				return getCollaboratorsOnOperation();
			}
			if (result.status === 409) {
				dispatchLoading({ type: LOADING_OFF });
				postWorkShiftScheduleSuccess([result.data.detail]);
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
			postWorkShiftScheduleError(error.message);
		}
	};

	const getWorkShiftSingleCollaboratorsOnOperation = async (
		id,
		viewHistoric
	) => {
		const baseUrl = generateUrlBase(
			`collaborators/${id}/work-shift/schedule?view-historic=${
				viewHistoric ?? false
			}`
		);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchGetSchenduleCollaboratorOperation({
					type: GET_OPERATION_SCHEDULE_COLABORATORS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				return result.data;
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const deleteWorkShiftSingleCollaboratorsOnOperation = async (
		id,
		workShitId
	) => {
		const baseUrl = generateUrlBase(
			`collaborators/${id}/work-shift/schedule/${workShitId}`
		);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'DELETE', {}, {}, {});
			if (result.status === 200) {
				getWorkShiftSingleCollaboratorsOnOperation(id);
				dispatchDeleteScheduleCollaborator({
					type: DELETE_OPERATION_SCHEDULE_COLABORATOR_SUCCESS,
				});
				dispatchLoading({ type: LOADING_OFF });
				Toast(
					'success',
					'Se ha eliminado el turno programado de forma exitosa'
				);
				return result.data;
			}
			return result;
		} catch (error) {
			dispatchDeleteScheduleCollaborator({
				type: DELETE_OPERATION_SCHEDULE_COLABORATOR_ERROR,
			});
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};
	const updateCollaboratorOpenWorkArea = async (
		personiaCollaboratorId,
		open
	) => {
		const data = {
			personiaCollaboratorId,
			open,
		};
		const baseUrl = generateUrlBase(
			`operations/collaborators/${personiaCollaboratorId}/open-work-area/${open}`
		);
		dispatchLoading({ type: LOADING_ON });
		try {
			const result = await genericApiCall(baseUrl, 'PUT', data, {}, {});
			if (result && result.status === 200) {
				dispatchLoading({ type: LOADING_OFF });
				Toast(
					'success',
					'Se ha modificado la configuración de restricción de checada.'
				);
				return getCollaboratorsOnOperation();
			} else if (result && result.status === 404) {
				dispatchLoading({ type: LOADING_OFF });
				Toast(
					'warning',
					'Error al modificar la configuración de restricción de checada'
				);
			} else if (result && result.status === 500) {
				dispatchLoading({ type: LOADING_OFF });
				Toast('error', 'Se encontró un error, notifique a su administrador');
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		getCollaboratorsOnOperation();
	}, []);

	return {
		getCollaboratorsOnOperation,
		getWorkCenterLocationCollaboratorsOnOperation,
		postWorkCenterLocationCollaboratorsOnOperation,
		postWorkShiftMassiveCollaboratorsOnOperation,
		postWorkShiftSingleCollaboratorsOnOperation,
		getWorkShiftSingleCollaboratorsOnOperation,
		deleteWorkShiftSingleCollaboratorsOnOperation,
		updateCollaboratorOpenWorkArea,
	};
};

export default useOperation;
