import { createContext, useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../business/Provider';
export const EmployeeMaintenanceServiceContext = createContext();

export const EmployeeMaintenanceServiceManagerProvider = ({ children }) => {
	const { postWorkShiftScheduleContextState } = useContext(StoreContext);
	const { statePostWorkShiftSchedule } = postWorkShiftScheduleContextState;
	useEffect(() => {
		if (statePostWorkShiftSchedule.data !== null) {
			const _data = statePostWorkShiftSchedule.data.map((data) => {
				return {
					error: data,
				};
			});
			console.log('data xxxxxxxxxxxxxxxx', _data);
			setErrorDataSchedule(_data);
			setOpenDialogErrors(true);
		}
	}, [statePostWorkShiftSchedule.data]);
	const [openDialogErrors, setOpenDialogErrors] = useState(false);
	const [errorDataSchedule, setErrorDataSchedule] = useState([]);
	function onCloseErrorDialog() {
		setOpenDialogErrors(false);
		setErrorDataSchedule([]);
	}

	return (
		<EmployeeMaintenanceServiceContext.Provider
			key={'Employee Maintenance'}
			displayName='Employee Maintenance'
			value={{ openDialogErrors, errorDataSchedule, onCloseErrorDialog }}>
			{children}
		</EmployeeMaintenanceServiceContext.Provider>
	);
};
