import { useReducer } from 'react';
import { actionTypesOperations } from '../../../../../business/constans/operation';
import {
	postWorkShiftSchedule_IS,
	postWorkShiftScheduleRed,
} from '../../../../../business/reducers/Assist/Collaborators/EmployeeMaintenanceRed/postSchedule';

export const usePostWorkShiftScheduleState = () => {
	const { POST_WORK_SHIFT_SCHEDULE } = actionTypesOperations;

	const [statePostWorkShiftSchedule, dispatch] = useReducer(
		postWorkShiftScheduleRed,
		postWorkShiftSchedule_IS
	);

	const postWorkShiftSchedule = () => {
		return dispatch({ type: POST_WORK_SHIFT_SCHEDULE.BASE });
	};

	const postWorkShiftScheduleSuccess = (data) => {
		return dispatch({ type: POST_WORK_SHIFT_SCHEDULE.SUCCESS, payload: data });
	};
	const postWorkShiftScheduleError = (data) => {
		return dispatch({ type: POST_WORK_SHIFT_SCHEDULE.ERROR, payload: data });
	};

	return {
		statePostWorkShiftSchedule,
		postWorkShiftSchedule,
		postWorkShiftScheduleSuccess,
		postWorkShiftScheduleError,
	};
};
