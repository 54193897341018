/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/tailwind.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import routerBrowser from './router/index';
import * as history from 'history';
import { MainLayout } from './layout/MainLayout';
import { NavigationProvider } from './context/navigationContext';
import {
	AuthenticatedTemplate,
	MsalProvider,
	useMsalAuthentication,
} from '@azure/msal-react';
import { Provider } from 'react-redux';
import axios from 'axios';
import { Buffer } from 'buffer';

import store from './store/index';
import { ReloadProvider } from './context/ReloadProvider';
import { Auth } from './Auth/auth';
import { LoadingProvider } from './context/LoadingContext';
import WrapperApp from './WrapperApp';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './Auth/b2cService/b2cServiceConfig';

import { PositionUserGPSProvider } from './context/PositionUserGPS';
import { FilterProvider } from './context/queries/FilterContext';

import {
	checkIsCallByPayroll,
	decodificarBase64,
	isBase64,
} from './utils/utils';
import { locale, addLocale } from 'primereact/api';
import { primeReactTranslations } from './primeReactTranslateOptions';

function App({ pca }) {
	addLocale('es', primeReactTranslations);

	locale('es');

	const History = history.createBrowserHistory();
	window.Buffer = Buffer;
	const { login, error } = useMsalAuthentication(
		InteractionType.Silent,
		loginRequest
	);
	const getQueryVariable = (variable) => {
		const query = window.location.search.substring(1);
		const vars = query.replace('#state', '&state').split('&');

		if (vars.length > 1) {
			const p2 = vars[1];
			let localStorageToken = p2.split('=');

			localStorage.setItem('accessToken', localStorageToken[1]);
		}
		for (const vrItem of vars) {
			const pair = vrItem.split('=');

			if (pair[0] === variable) {
				return pair[1];
			}
		}
		return '';
	};

	useEffect(() => {
		if (error) {
			login(InteractionType.Redirect, loginRequest);
			window.localStorage.clear();
		} else {
			// get state from master portal, if the state is not present, will do a redirection to master portal.
			const stateQueryVariable = getQueryVariable('state');

			let state = '';

			if (stateQueryVariable !== '') {
				//compare instance id from session storage with instance id from master portal
				const sessionStateStorage = JSON.parse(
					sessionStorage.getItem('sessionState')
				);
				state = JSON.parse(
					Buffer.from(stateQueryVariable, 'base64').toString()
				);

				state.userAgent = window.navigator.userAgent.toString();
				const sessionStateJson = state;

				if (sessionStateJson?.instanceId !== sessionStateStorage?.instanceId) {
					//remove session storage
					sessionStorage.removeItem('sessionState');
					window.localStorage.clear();
				}
			}
			if (!sessionStorage.sessionState) {
				// aqui va la funcion
				if (!stateQueryVariable || stateQueryVariable.length === 0) {
					window.location.replace(msalConfig.ctiPortalURI);
				} else {
					state = JSON.parse(
						Buffer.from(stateQueryVariable, 'base64').toString()
					);
					state.userAgent = window.navigator.userAgent.toString();

					const sessionStateJson = JSON.stringify(state);
					sessionStorage.setItem('sessionState', sessionStateJson);

					const gId = isBase64(state.gId)
						? decodificarBase64(state.gId)
						: state.gId;

					const isPayRollCall = checkIsCallByPayroll(gId);

					sessionStorage.setItem('isPayrollCall', isPayRollCall);

					axios.defaults.headers.common = {
						...axios.defaults.headers.common,
						sessionState: sessionStateJson,
					};
				}
			} else {
				const sessionStateStorage = sessionStorage.getItem('sessionState');

				// set as default header the sessionState
				axios.defaults.headers.common = {
					...axios.defaults.headers.common,
					sessionState: sessionStateStorage,
				};
			}
		}
	}, [error, login]);

	if (localStorage.getItem('accessToken') === null) {
		localStorage.removeItem('currentTap');
	}

	const localStorageToken = localStorage.getItem('accessToken');
	if (
		localStorageToken !== undefined &&
		localStorageToken !== null &&
		localStorageToken !== ''
	) {
		function parseJwt(localStorageToken) {
			let base64Url = localStorageToken.split('.')[1];
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			let jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			);
			return JSON.parse(jsonPayload);
		}

		const dataInfo = parseJwt(localStorageToken);
		window.idzCustomData = {
			cust_asesorcontpaqi: false,
			cust_certificaciones: 0,
			cust_cid: 0,
			cust_email: dataInfo.email,
			cust_excomercial: false,
			cust_excontable: false,
			cust_mesaservicio: false,
			cust_nivel: 'value',
			cust_tipo: 'value',
			extid: dataInfo.extension_CONTPAQiID,
			logged_in: true,
			page_type: 'singlepage',
			distribuidor_de_servicio: 'value',
			distribuidor_de_venta: 'value',
			cust_url: 'https://colabora.contpaqinube.com',
			cust_nombre: 'value',
			cust_idsistema: 9084,
			rfc: '',
			razon_social: '',
			telefono: 0,
			ciudad: '',
			estado: '',
			pais: '',
		};

		let idz = document.createElement('script');
		idz.type = 'text/javascript';
		idz.async = true;
		idz.src =
			document.location.protocol + '//halc.iadvize.com/iadvize.js?sid=5665';
		let s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(idz, s);

		window.inlineManualTracking = {};
		window.inlineManualOptions = { language: 'en' };
		let domainInLine =
			window.location.hostname.split('.')[
				window.location.hostname.split('.').length - 2
			];

		let inlineID = {
			devctinube: '6b27d5a22b456d0bfcdd15bef4f87431',
			testdevcontpaqinube: '6b27d5a22b456d0bfcdd15bef4f87431',
			testcontpaqinube: '6b27d5a22b456d0bfcdd15bef4f87431',
			contpaqinube: '6a289377e195ad514f3cfc50d04a07c8',
		};

		!(function (e, n, a, t, i, r, s) {
			(e[i] =
				e[i] ||
				function () {
					(e[i].q = e[i].q || []).push(arguments);
				}),
				(e[i].l = 1 * new Date()),
				(e[i].snippet = {
					version: '2.0',
				}),
				(r = n.createElement(a)),
				(s = n.getElementsByTagName(a)[0]),
				(r.async = 1),
				(r.src = t),
				(r.id = t + '-' + i),
				s.parentNode.insertBefore(r, s);
		})(
			window,
			document,
			'script',
			`https://cdn.inlinemanual.com/inm/loader/loader.${inlineID[domainInLine]}.js`,
			'InlineManual'
		);

		InlineManual('boot', {
			uid: dataInfo.email,
			email: dataInfo.email,
			username: dataInfo.email,
			name: dataInfo.given_name,
			product: 'colabora',
		});
	}
	useEffect(() => {
		const disableBackButton = (event) => {
			event.preventDefault();
			window.history.forward();
		};
		// Agregar el event listener cuando el componente se monta
		window.addEventListener('popstate', disableBackButton);
		// Eliminar el event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener('popstate', disableBackButton);
		};
	}, []);

	return (
		<AuthenticatedTemplate>
			<Provider store={store}>
				<ReloadProvider>
					<Auth>
						<MsalProvider instance={pca}>
							<PositionUserGPSProvider>
								<LoadingProvider>
									<FilterProvider>
										<WrapperApp>
											<BrowserRouter history={History}>
												<NavigationProvider>
													<Routes>
														<Route exact path='/' element={<MainLayout />}>
															{routerBrowser}
														</Route>
													</Routes>
												</NavigationProvider>
											</BrowserRouter>
										</WrapperApp>
									</FilterProvider>
								</LoadingProvider>
							</PositionUserGPSProvider>
						</MsalProvider>
					</Auth>
				</ReloadProvider>
			</Provider>
		</AuthenticatedTemplate>
	);
}

export default App;
