import { useReducer } from 'react';
import { getFiltersOrganizationChartRed } from '../../../business/reducers/organizationChart/getFiltersOrganizationCharRed';
import { getFiltersOrganizationChartIS } from '../../../business/initialState/organizationChart/getFiltersOrganizationChartIS';
import {
	GET_FILTERS_ORGANIZATION_CHART,
	GET_FILTERS_ORGANIZATION_CHART_ERROR,
	GET_FILTERS_ORGANIZATION_CHART_SUCCESS,
	PUT_ORG_CHART_LEADERS,
	PUT_ORG_CHART_LEADERS_CLEAR,
	PUT_ORG_CHART_LEADERS_ERROR,
	PUT_ORG_CHART_LEADERS_SUCCESS,
} from '../../../business/constants';
import { putLeadersOrganizationChartReducer } from '../../../business/reducers/organizationChart/putLeadersOrganizationChart';
import { putOrganizationChartLeadersIS } from '../../../business/initialState/organizationChart/putOrganizationChartLeaders';

export const useGetOrganizationChart = () => {
	const [stateGetOrgChart, dispatch] = useReducer(
		getFiltersOrganizationChartRed,
		getFiltersOrganizationChartIS
	);

	const getOrgChartInit_DP = () => {
		dispatch({
			type: GET_FILTERS_ORGANIZATION_CHART,
		});
	};
	const getOrgChartSuccess_DP = (data) => {
		dispatch({
			type: GET_FILTERS_ORGANIZATION_CHART_SUCCESS,
			payload: data,
		});
	};
	const getOrgChartError_DP = (data = '') => {
		dispatch({
			type: GET_FILTERS_ORGANIZATION_CHART_ERROR,
			payload: data,
		});
	};

	return {
		stateGetOrgChart,
		getOrgChartInit_DP,
		getOrgChartSuccess_DP,
		getOrgChartError_DP,
	};
};

export const usePutOrganizationChart = () => {
	const [state, dispatch] = useReducer(
		putLeadersOrganizationChartReducer,
		putOrganizationChartLeadersIS
	);

	const putOrgChart_DP = () => {
		return dispatch({ type: PUT_ORG_CHART_LEADERS });
	};
	const putOrgChartClear_DP = () => {
		return dispatch({ type: PUT_ORG_CHART_LEADERS_CLEAR });
	};

	const putOrgChartSuccess_DP = (data) => {
		return dispatch({ type: PUT_ORG_CHART_LEADERS_SUCCESS, payload: data });
	};

	const putOrgChartError_DP = (data = null) => {
		return dispatch({ type: PUT_ORG_CHART_LEADERS_ERROR, payload: data });
	};

	return {
		state,
		putOrgChart_DP,
		putOrgChartSuccess_DP,
		putOrgChartError_DP,
		putOrgChartClear_DP,
	};
};
