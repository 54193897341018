import React, { createContext, useReducer } from 'react';
// reducers
import { getCollaboratorReducer } from './reducers/collaborators/GetCollaboratorReducer';
import { sendInvitationsReducer } from './reducers/invitations/sendInvitationsReducer';
import { acceptInfoCollaboratorReducer } from './reducers/collaborators/AcceptInformationCollaboratorReducer';
import { rejectInfoCollaboratorReducer } from './reducers/collaborators/RejectInformationCollaboratorReducer';
import { getCompanySettingReducer } from './reducers/company/GetCompanySettingReducer';
import { getCompanySettingNotificationReducer } from './reducers/company/GetCompanyNotificationReducer';
import { postCompanyAttendanceReducer } from './reducers/company/PostCompanyAttendanceReducer';
import { postCompanyIncidentReducer } from './reducers/company/PostCompanyIncidentReducer';
import { postWorkCenterReducer } from './reducers/catalogs/workCenter/postWorkCenterReducer';
import { getWorkCenterByIdReducer } from './reducers/catalogs/workCenter/getWorkCenterByIdReducer';
import { patchWorkCenterReducer } from './reducers/catalogs/workCenter/patchWorkCenterReducer';
import { deleteWorkCenterReducer } from './reducers/catalogs/workCenter/deleteWorkCenterReducer';
import { putStatusWorkCenterReducer } from './reducers/catalogs/workCenter/putStatusWorkCenterReducer';
import { getHolidaysReducer } from './reducers/catalogs/holidays/getHolidaysReducer';
import { getSchedulesReducer } from './reducers/catalogs/schedules/getScheduleRecuer';
import { postSchedulesReducer } from './reducers/catalogs/schedules/postScheduleRecuer';
import { putSchedulesReducer } from './reducers/catalogs/schedules/putScheduleRecuer';

import { loading } from './reducers/loading';

//const initialState
import { getCollaboratorInitialState } from './initialState/collaborator/getCollaboratorInitialState';
import { sendInvitationInitialState } from './initialState/invitations/sendInvitationsInitialState';
import { acceptedInformationCollaboratorInitialState } from './initialState/collaborator/acceptedInformationCollaboratorInitialState';
import { rejectInformationCollaboratorInitialState } from './initialState/collaborator/rejectInformationCollaboratorInitialState';
import { companyInitialState } from './initialState/invitations/companyInitialState';
import { companySettingInitialState } from './initialState/company/companySettingInitialState';
import { companyReducer } from './reducers/company/companyReducer';
import { getStatusInvitationsInitialState } from './initialState/invitations/getStatustInvitationsInitialState';
import { getStatusInvitationsReducer } from './reducers/invitations/getStatusInvitationReducer';
import { reImportInfoReducer } from './reducers/invitations/reImportDataInfoReducer';
import { reImportInfoInitialState } from './initialState/invitations/reImportInfoInitialState';
import { invitationForwardInitialState } from './initialState/invitations/invitationForwardInitialState';
import { invitationForwardReducer } from './reducers/invitations/invitationForwardReducer';
import { getWorkCenterInitialState } from './initialState/catalogs/workCenter/getWorkCenterInitialState';
import { getWorkCenterReducer } from './reducers/catalogs/workCenter/getWorkCenterReducer';
import { getCollaboratorsWorkCenterInitialState } from './initialState/catalogs/workCenter/getCollaboratorsWorkCenterInitialState';
import { getCollaboratorsWorkCenterReducer } from './reducers/catalogs/workCenter/getCollaboratorsWorkCenterReducer';
import { postWorkCenterInitialState } from './initialState/catalogs/workCenter/postWorkCenterInitialState';
import { getWorkCenterByIdInitialState } from './initialState/catalogs/workCenter/getWorkCenterByIdInitialState';
import { patchWorkCenterInitialState } from './initialState/catalogs/workCenter/patchWorkCenterInitialState';
import { deleteWorkCenterState } from './initialState/catalogs/workCenter/deleteWorkCenterState';
import { putStatusWorkCenterState } from './initialState/catalogs/workCenter/putStatusWorkCenterState';
import { holidaysInitState } from './initialState/catalogs/holidays/holidaysInitState';
import { deleteHolidaysReducer } from './reducers/catalogs/holidays/deleteHolidaysReducer';
import { postHolidaysReducer } from './reducers/catalogs/holidays/postHolidaysReducer';
import { putHolidaysReducer } from './reducers/catalogs/holidays/putHolidaysReducer';
import { putHolidaysInitState } from './initialState/catalogs/holidays/putHolidaysInitState';
import { deleteHolidaysInitState } from './initialState/catalogs/holidays/deleteHolidaysInitState';
import { postHolidaysInitState } from './initialState/catalogs/holidays/postHolidayInitState';
import { getScheduleInitialState } from './initialState/catalogs/schedules/getScheduleInitialState';
import { postScheduleInitialState } from './initialState/catalogs/schedules/postScheduleInitialState';
import { putScheduleInitialState } from './initialState/catalogs/schedules/putScheduleInitialState';
import { getScheduleWorkShiftReducer } from './reducers/catalogs/schedules/getSchenduleWorkShiftReducer';
import { getScheduleWorkShiftInitialState } from './initialState/catalogs/schedules/getScheduleWorkShiftInitialState';
import { getOperationCollaboratorsInitialState } from './initialState/catalogs/operation/getOperationCollaboratorsInitialState';
import { getOperationCollaboratorsReducer } from './reducers/catalogs/operation/getOperationCollaboratorsReducer';
import { getHolidaysInitState } from './initialState/catalogs/holidays/getHolidaysInitialState';
import { getHolidaysByYearReducer } from './reducers/catalogs/holidays/getHolidaysByYearReducer';
import { importDaysOffReducer } from './reducers/catalogs/holidays/importDaysOffReducer';
import { importDaysOffInitState } from './initialState/catalogs/holidays/importDaysOffInitialState';
import { getWorkCenterLocationByCollaboratorInitialState } from './initialState/catalogs/operation/getWorkCenterLocationByCollaboratorInitialState';
import { getWorkCenterLocationByCollaboratorReducer } from './reducers/catalogs/operation/getWorkCenterLocationByCollaboratorReducer';
import { postWorkCenterLocationCollaboratorInitialState } from './initialState/catalogs/operation/postWorkCenterLocationCollaboratorInitialState';
import { postWorkCenterLocationColaboratorReducer } from './reducers/catalogs/operation/postWorkCenterLocationColaboratorReducer';
import { postScheduleCollaboratorReducer } from './reducers/catalogs/operation/postScheduleCollaboratorReducer';
import { postScheduleCollaboratorInitialState } from './initialState/catalogs/operation/postScheduleCollaboratorInitialState';
import { getScheduleCollaboratorReducer } from './reducers/catalogs/operation/getScheduleCollaboratorReducer';
import { getScheduleCollaboratorInitialState } from './initialState/catalogs/operation/getScheduleCollaboratorInitialState';
import { deleteScheduleCollaboratorInitialState } from './initialState/catalogs/operation/deleteScheduleCollaboratorInitialState';
import { deleteScheduleCollaboratorReducer } from './reducers/catalogs/operation/deleteScheduleCollaboratorReducer';
import { exportsReducer } from './reducers/exports/exportsReducer';
import { exportInitialState } from './initialState/exports/exportInitialState';
import { getPeriodReducer } from './reducers/periods/getPeriodReducer';
import { getPeriodInitialState } from './initialState/periods/getPeriodInitialState';
import { getIncidencesInitialState } from './initialState/incidences/getIncidencesInitialState';
import { getIncidencesReducer } from './reducers/incidences/getIncidencesReducer';
import { postIncidencesReducer } from './reducers/incidences/postIncidencesReducer';
import { postIncidencesInitialState } from './initialState/incidences/postIncidencesInitialState';
import { getIncidencesByProcessIdReducer } from './reducers/incidences/getIncidencesByProcessId';
import { getIncidencesByProcessIdInitialState } from './initialState/incidences/getIncidencesByProcessIdInitialState';
import { getTypeIncidencesInitialState } from './initialState/incidences/getTypeIncidencesinitialState';
import { getTypeIncidencesReducer } from './reducers/incidences/getTypeIncidences';
import { postNewIncidencesReducer } from './reducers/incidences/postNewIncidenteReducer';
import { postNewIncidencesInitialState } from './initialState/incidences/postNewIncidenceInitialState';
import { putIncidencesReducer } from './reducers/incidences/putIncidencesReducer';
import { putIncidencesInitialState } from './initialState/incidences/putIncidencesInitialState';
import { getAccumulatedReducer } from './reducers/incidences/getAccumulatedReducer';
import { getAccumulatedInitialState } from './initialState/incidences/getAccumulatedInitialState';
import { getCatalogsReportReducer } from './reducers/reports/getCatalogsReportReducer';
import { getCatalogInitialState } from './initialState/reports/getCatalogInitialState';
import { getQueriesReducer } from './reducers/queries/getQueriesReducer';
import { getQueriesInitialState } from './initialState/queries/getQueriesInitialState';
import { editIncidencesReducer } from './reducers/incidences/editIncidencesReducer';
import { editIncidencesInitialState } from './initialState/incidences/editIncidencesInitialState';
import { putImportPeriodsReducer } from './reducers/periods/putImportPeriodsReducer.jsx';
import { putImportPeriodsInitialState } from './initialState/periods/putImportPeriodsInitialState.js';
import { getCompanyVerifyLicenseReducer } from './reducers/company/getCompanyVerifyLicense.js';
import { getCompanyVerifyLicenseInitState } from './initialState/company/getCompanyVerifyLicense';
import { getOrganizationChartRed } from './reducers/organizationChart/getOrganizationCharRed.js';
import { getOrganizationChartIS } from './initialState/organizationChart/getOrganizationChartIS.js';
import { getLeadersOrganizationChartRed } from './reducers/organizationChart/getLeadersOrganizationCharRed.js';
import { getLeadersOrganizationChartIS } from './initialState/organizationChart/getLeadersOrganizationChartIS.js';

import {
	useGetInvitationsState,
	useSendUnInvitedState,
} from '../hooks/States/Invitations/useInvitationsState.jsx';
import { useVerifyLicenseState } from '../hooks/States/license/useVerifyLicenseState.jsx';
import { useImportEmployee } from '../hooks/States/imports/useImportEmployee.jsx';
import { useLoading } from '../hooks/States/Loading/useLoading.jsx';
import {
	useGetOrganizationChart,
	usePutOrganizationChart,
} from '../hooks/States/orgarnizationChart/useOrganizationChart.jsx';
import { usePostWorkShiftScheduleState } from '../hooks/States/assist/collaborators/employeeMaintenance/useEmployeeMaintenance.jsx';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
	const getInvitations = useGetInvitationsState();
	const useVerifyLicense = useVerifyLicenseState();
	const sendUnInviteInvitationsState = useSendUnInvitedState();
	const importEmployeeContext = useImportEmployee();
	const loadingContextState = useLoading();
	const postWorkShiftScheduleContextState = usePostWorkShiftScheduleState();

	//Organization Chart context state
	const getOrgChartContextState = useGetOrganizationChart();
	const putOrgChartContextState = usePutOrganizationChart();

	const [loadingState, dispatchLoading] = useReducer(loading, false);

	const [getCollaboratorsState, dispatchGetCollaborators] = useReducer(
		getCollaboratorReducer,
		getCollaboratorInitialState
	);

	const [postInvitationsState, dispatchPostInvitations] = useReducer(
		sendInvitationsReducer,
		sendInvitationInitialState
	);

	const [acceptInfoCollaboratorState, dispatchAcceptInfoCollaborator] =
		useReducer(
			acceptInfoCollaboratorReducer,
			acceptedInformationCollaboratorInitialState
		);
	const [rejectInfoCollaboratorState, dispatchRejectInfoCollaborator] =
		useReducer(
			rejectInfoCollaboratorReducer,
			rejectInformationCollaboratorInitialState
		);

	const [companyState, dispatchCompany] = useReducer(
		companyReducer,
		companyInitialState
	);

	const [getCompanySettings, dispatchCompanySetting] = useReducer(
		getCompanySettingReducer,
		companySettingInitialState
	);

	const [postCompanySettingsAttendance, dispatchCompanySettingsAttendance] =
		useReducer(postCompanyAttendanceReducer, companySettingInitialState);

	const [postCompanySettingsIncident, dispatchCompanySettingsIncident] =
		useReducer(postCompanyIncidentReducer, companySettingInitialState);

	const [getCompanySettingNotification, dispatchCompanySettingNotification] =
		useReducer(
			getCompanySettingNotificationReducer,
			companySettingInitialState
		);
	const [getStatusInvitation, dispatchGetStatusInvitation] = useReducer(
		getStatusInvitationsReducer,
		getStatusInvitationsInitialState
	);

	const [reImportData, dispatchReImportData] = useReducer(
		reImportInfoReducer,
		reImportInfoInitialState
	);

	const [invitationForward, dispatchInvitationForward] = useReducer(
		invitationForwardReducer,
		invitationForwardInitialState
	);

	const [getWorkCenterState, dispatchGetWorkCenter] = useReducer(
		getWorkCenterReducer,
		getWorkCenterInitialState
	);

	const [getWorkCenterByIdState, dispatchGetWorkCenterByIdState] = useReducer(
		getWorkCenterByIdReducer,
		getWorkCenterByIdInitialState
	);

	const [deleteWorkCenterByIdState, dispatchDeleteWorkCenterById] = useReducer(
		deleteWorkCenterReducer,
		deleteWorkCenterState
	);

	const [putStatusWorkCenterByIdState, dispatchPutStatusWorkCenter] =
		useReducer(putStatusWorkCenterReducer, putStatusWorkCenterState);

	const [getCollaboratorsWorkCenterState, dispatchGetCollaboratorsWorkCenter] =
		useReducer(
			getCollaboratorsWorkCenterReducer,
			getCollaboratorsWorkCenterInitialState
		);

	const [postWorkCenterState, dispatchPostWorkCenter] = useReducer(
		postWorkCenterReducer,
		postWorkCenterInitialState
	);

	const [patchWorkCenterState, dispatchPatchWorkCenter] = useReducer(
		patchWorkCenterReducer,
		patchWorkCenterInitialState
	);

	const [getHolidayState, dispatchGetHolidays] = useReducer(
		getHolidaysReducer,
		holidaysInitState
	);
	const [deleteHolidaysState, dispatchDeleteHolidays] = useReducer(
		deleteHolidaysReducer,
		deleteHolidaysInitState
	);

	const [postHolidaysState, dispatchPostHolidays] = useReducer(
		postHolidaysReducer,
		postHolidaysInitState
	);

	const [putHolidaysState, dispatchPutHolidays] = useReducer(
		putHolidaysReducer,
		putHolidaysInitState
	);

	const [getScheduleState, dispatchGetSchedule] = useReducer(
		getSchedulesReducer,
		getScheduleInitialState
	);

	const [postScheduleState, dispatchPostSchedule] = useReducer(
		postSchedulesReducer,
		postScheduleInitialState
	);

	const [putScheduleState, dispatchPutSchedule] = useReducer(
		putSchedulesReducer,
		putScheduleInitialState
	);

	const [getScheduleWorkShiftState, dispatchGetScheduleWorkShift] = useReducer(
		getScheduleWorkShiftReducer,
		getScheduleWorkShiftInitialState
	);

	const [getOperationCollaboratorsState, dispatchGetOperationCollaborators] =
		useReducer(
			getOperationCollaboratorsReducer,
			getOperationCollaboratorsInitialState
		);

	const [getHolidayByYearState, dispatchGetHolidaysByYear] = useReducer(
		getHolidaysByYearReducer,
		getHolidaysInitState
	);

	const [importDaysOffState, dispatchImportDaysOff] = useReducer(
		importDaysOffReducer,
		importDaysOffInitState
	);

	const [
		getOperationWorkCenterLocationCollaboratorsState,
		dispatchGetOperationWorkCenterLocationCollaborators,
	] = useReducer(
		getWorkCenterLocationByCollaboratorReducer,
		getWorkCenterLocationByCollaboratorInitialState
	);

	const [
		postOperationWorkCenterLocationCollaboratorsState,
		dispatchPostOperationWorkCenterLocationCollaborators,
	] = useReducer(
		postWorkCenterLocationColaboratorReducer,
		postWorkCenterLocationCollaboratorInitialState
	);

	const [
		postSchenduleCollaboratorOperationState,
		dispatchPostSchenduleCollaboratorOperation,
	] = useReducer(
		postScheduleCollaboratorReducer,
		postScheduleCollaboratorInitialState
	);

	const [
		getSchenduleCollaboratorOperationState,
		dispatchGetSchenduleCollaboratorOperation,
	] = useReducer(
		getScheduleCollaboratorReducer,
		getScheduleCollaboratorInitialState
	);

	const [deleteScheduleCollaboratorState, dispatchDeleteScheduleCollaborator] =
		useReducer(
			deleteScheduleCollaboratorReducer,
			deleteScheduleCollaboratorInitialState
		);

	const [exportState, dispatchExport] = useReducer(
		exportsReducer,
		exportInitialState
	);

	const [getPeriodState, dispatchGetPeriod] = useReducer(
		getPeriodReducer,
		getPeriodInitialState
	);

	const [getIncidencesState, dispatchGetIncidences] = useReducer(
		getIncidencesReducer,
		getIncidencesInitialState
	);
	const [getIncidencesStateByProcessId, dispatchGetIncidencesByProcessId] =
		useReducer(
			getIncidencesByProcessIdReducer,
			getIncidencesByProcessIdInitialState
		);
	const [postIncidencesState, dispatchPostIncidences] = useReducer(
		postIncidencesReducer,
		postIncidencesInitialState
	);
	const [postNewIncidencesState, dispatchPostNewIncidences] = useReducer(
		postNewIncidencesReducer,
		postNewIncidencesInitialState
	);
	const [getTypeIncidencesState, dispatchGetTypeIncidences] = useReducer(
		getTypeIncidencesReducer,
		getTypeIncidencesInitialState
	);
	const [putIncidencesState, dispatchPutIncidences] = useReducer(
		putIncidencesReducer,
		putIncidencesInitialState
	);
	const [getAccumulatedState, dispatchGetAccumulated] = useReducer(
		getAccumulatedReducer,
		getAccumulatedInitialState
	);

	const [getCatalogReportState, dispatchGetCatalogReport] = useReducer(
		getCatalogsReportReducer,
		getCatalogInitialState
	);
	const [getQueriesState, dispatchGetQueries] = useReducer(
		getQueriesReducer,
		getQueriesInitialState
	);
	const [editIncidencesState, dispatchEditIncidences] = useReducer(
		editIncidencesReducer,
		editIncidencesInitialState
	);

	const [putImportPeriodsState, dispatchPutImportPeriods] = useReducer(
		putImportPeriodsReducer,
		putImportPeriodsInitialState
	);

	const [companyVerifyLicenseState, dispatchCompanyVerifyLicense] = useReducer(
		getCompanyVerifyLicenseReducer,
		getCompanyVerifyLicenseInitState
	);

	const [getOrganizationChartState, dispatchGetOrganizationChart] = useReducer(
		getOrganizationChartRed,
		getOrganizationChartIS
	);

	const [getLeadersOrgChartState, dispatchGetLeadersOrgChart] = useReducer(
		getLeadersOrganizationChartRed,
		getLeadersOrganizationChartIS
	);

	return (
		<StoreContext.Provider
			value={{
				putOrgChartContextState,
				loadingState,
				dispatchLoading,
				getCollaboratorsState,
				dispatchGetCollaborators,
				getInvitationsState: getInvitations.state,
				dispatchGetInvitations: getInvitations.dispatch,
				postInvitationsState,
				dispatchPostInvitations,
				sendUnInviteState: sendUnInviteInvitationsState.state,
				dispatchSendUnInvite: sendUnInviteInvitationsState.dispatch,
				acceptInfoCollaboratorState,
				dispatchAcceptInfoCollaborator,
				rejectInfoCollaboratorState,
				dispatchRejectInfoCollaborator,
				companyState,
				dispatchCompany,
				getStatusInvitation,
				dispatchGetStatusInvitation,
				reImportData,
				dispatchReImportData,
				verifyAvailableLicenseState: useVerifyLicense.state,
				dispatchVerifyAvailableLicense: useVerifyLicense.dispatch,
				invitationForward,
				dispatchInvitationForward,
				companyInitialState,
				getCompanySettings,
				dispatchCompanySetting,
				postCompanySettingsAttendance,
				dispatchCompanySettingsAttendance,
				postCompanySettingsIncident,
				dispatchCompanySettingsIncident,

				getWorkCenterState,
				dispatchGetWorkCenter,
				getCollaboratorsWorkCenterState,
				dispatchGetCollaboratorsWorkCenter,
				postWorkCenterState,
				dispatchPostWorkCenter,

				getCompanySettingNotification,
				dispatchCompanySettingNotification,

				getWorkCenterByIdState,
				dispatchGetWorkCenterByIdState,

				patchWorkCenterState,
				dispatchPatchWorkCenter,

				deleteWorkCenterByIdState,
				dispatchDeleteWorkCenterById,

				putStatusWorkCenterByIdState,
				dispatchPutStatusWorkCenter,

				getHolidayState,
				dispatchGetHolidays,

				postHolidaysState,
				dispatchPostHolidays,

				putHolidaysState,
				dispatchPutHolidays,

				deleteHolidaysState,
				dispatchDeleteHolidays,

				getScheduleState,
				dispatchGetSchedule,

				postScheduleState,
				dispatchPostSchedule,

				putScheduleState,
				dispatchPutSchedule,

				getScheduleWorkShiftState,
				dispatchGetScheduleWorkShift,

				getOperationCollaboratorsState,
				dispatchGetOperationCollaborators,

				getHolidayByYearState,
				dispatchGetHolidaysByYear,

				importDaysOffState,
				dispatchImportDaysOff,

				getOperationWorkCenterLocationCollaboratorsState,
				dispatchGetOperationWorkCenterLocationCollaborators,

				postOperationWorkCenterLocationCollaboratorsState,
				dispatchPostOperationWorkCenterLocationCollaborators,

				postSchenduleCollaboratorOperationState,
				dispatchPostSchenduleCollaboratorOperation,

				getSchenduleCollaboratorOperationState,
				dispatchGetSchenduleCollaboratorOperation,

				deleteScheduleCollaboratorState,
				dispatchDeleteScheduleCollaborator,

				exportState,
				dispatchExport,

				getPeriodState,
				dispatchGetPeriod,

				getIncidencesState,
				dispatchGetIncidences,

				postIncidencesState,
				dispatchPostIncidences,

				getIncidencesStateByProcessId,
				dispatchGetIncidencesByProcessId,

				getTypeIncidencesState,
				dispatchGetTypeIncidences,

				postNewIncidencesState,
				dispatchPostNewIncidences,

				putIncidencesState,
				dispatchPutIncidences,

				getAccumulatedState,
				dispatchGetAccumulated,

				getCatalogReportState,
				dispatchGetCatalogReport,

				getQueriesState,
				dispatchGetQueries,

				editIncidencesState,
				dispatchEditIncidences,

				putImportPeriodsState,
				dispatchPutImportPeriods,

				companyVerifyLicenseState,
				dispatchCompanyVerifyLicense,

				getOrganizationChartState,
				dispatchGetOrganizationChart,

				importEmployeeContext,

				getOrgChartContextState,

				getLeadersOrgChartState,
				dispatchGetLeadersOrgChart,

				loadingContextState,
				postWorkShiftScheduleContextState,
			}}
			displayName='General Store'>
			{children}
		</StoreContext.Provider>
	);
};
