import { actionTypesOperations } from '../../../../constans/operation';

export const postWorkShiftSchedule_IS = {
	data: null,
	error: null,
	loading: false,
	isLoadData: false,
};
export const postWorkShiftScheduleRed = (state, actions) => {
	switch (actions.type) {
		case actionTypesOperations.POST_WORK_SHIFT_SCHEDULE.BASE:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
				isLoadData: false,
			};
		case actionTypesOperations.POST_WORK_SHIFT_SCHEDULE.ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
				data: null,
				isLoadData: false,
			};
		case actionTypesOperations.POST_WORK_SHIFT_SCHEDULE.SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
				isLoadData: true,
			};
		default:
			return state;
	}
};
