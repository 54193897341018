import { useContext } from 'react';
import { OrganizationChartContext } from '../../../../Services/OrganizationChart/OrganizationCharService';
import { OrganizationChartNames } from '../../../../namesConstants/names';

export const categoriesRadios = [
	{
		key: '1',
		name: OrganizationChartNames.AllCollaborators,
		value: false,
	},
	{
		key: '2',
		name: OrganizationChartNames.InvitationAcceptedCollaborators,
		value: true,
	},
];

export const getDropsMultiData = (service) => {
	const dropsMulti = [
		{
			name: 'name',
			label: ' Tipos de periodos',
			placeholder: 'Seleccione un tipo de periodo',
			optionLabel: 'name',
			value: service.typePeriodSelected,
			options: service.periodsTypesData,
			onChange: service.onHandleTypePeriods,
			setState: service.setTypePeriodSelected,
			filter: false,
		},
		{
			name: 'name',
			label: ' Departamentos',
			placeholder: 'Seleccione un departamento',
			optionLabel: 'name',
			value: service.departmentsSelected,
			options: service.departmentsData,
			onChange: service.onHandleDepartments,
			setState: service.setDepartmentsSelected,
			filter: false,
		},
		{
			name: 'name',
			label: ' Puestos',
			placeholder: 'Seleccione un puesto',
			optionLabel: 'name',
			value: service.positionsSelected,
			options: service.positionsData,
			onChange: service.onHandlePositions,
			setState: service.setPositionsSelected,
			filter: false,
		},
		{
			name: 'name',
			label: 'Turnos',
			placeholder: 'Seleccione un turno',
			optionLabel: 'name',
			value: service.shiftSelected,
			options: service.shiftData,
			onChange: service.onHandleShifts,
			setState: service.setShiftSelected,
			filter: false,
		},
		{
			name: 'name',
			label: ' Centros de trabajo',
			placeholder: 'Seleccione un centro de trabajo',
			optionLabel: 'name',
			value: service.workCenterSelected,
			options: service.workCenterData,
			onChange: service.onHandleWorkCenters,
			setState: service.setWorkCenterSelected,
			filter: false,
		},
		{
			name: 'name',
			label: ' Líder actual',
			placeholder: 'Líder actual',
			optionLabel: 'name',
			value: service.actualLeaderSelected,
			options: service.actualLeaderData,
			onChange: service.onHandleActualLeader,
			setState: service.setActualLeaderSelected,
			filter: false,
		},
		{
			name: 'name',
			label: 'Colaboradores',
			placeholder: 'Seleccione un colaborador',
			optionLabel: 'name',
			value: service.collaboratorSelected,
			options: service.collaboratorData,
			onChange: service.onHandleCollaborators,
			setState: service.setCollaboratorSelected,
			filter: false,
		},
	];
	return dropsMulti;
};
