import { createActionTypes } from '../../../utils/utils';

const actionBases = [
	'POST_WORK_SHIFT_SCHEDULE',
	// Puedes agregar más aquí
];

export const actionTypesOperations = actionBases.reduce((acc, base) => {
	acc[base] = createActionTypes(base);
	return acc;
}, {});
