import React, { useContext } from 'react';
import { OrganizationChartContext } from '../../Services/OrganizationChart/OrganizationCharService';
import { MainContainer } from '../../components/MainContainer';
import { OrgChartTableComponent } from './components/Table/OrgCharTable';
import { SidebarOrgChartComponent } from './components/Sidebar/SidebarOrgChart';
import { DialogOrgChart } from './components/Dialog';
import { StoreContext } from '../../business/Provider';
import { ModalErrors } from './components/ModalErrors';
export const OrganizationChart = () => {
	const service = useContext(OrganizationChartContext);
	const contextLoading = useContext(StoreContext);
	return (
		<MainContainer>
			<div className='flex w-full h-full flex-row p-1 border relative'>
				<SidebarOrgChartComponent service={service} />
				<OrgChartTableComponent service={service} />
				<DialogOrgChart service={service} contextLoading={contextLoading} />
				<ModalErrors service={service} />
			</div>
		</MainContainer>
	);
};
